.App {
  min-height: 100vh;
  background-color: #a1ddee;
  font-family: 'Lexend Deca', sans-serif;
  background-image: url('./assets/background-landscape.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: auto;
}

.App > .background {
  backdrop-filter: blur(8px);
  background-color: rgba(0,0,0,0.4);
  overflow-x: hidden;
}

.App .button {
  background-image: linear-gradient(90deg, #020222 0%, #090999 50%, #00ddff 100%);
}

.App-header {
  z-index: 2;
  min-height: 8vh;
  height: 4vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  border: 0;
  /*border-bottom: 1px solid rgba(255,255,255, 0.15);*/
}

.App-header > .title {
  z-index: 2;
  margin: 0;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}

.App-header > .menu-spacer {
  width: auto;
  margin: auto;
}

.App-header > .toolbox {
  width: 200px;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.App-main-container {
  min-height: 80vh;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  /*background: #efefef;*/
}

.App-footer {
  /*min-height: calc(12vh - 1px);*/
  min-height: 12vh;
  /*background: #06080c;*/
  /*backdrop-filter: blur(8px);*/
  width: 100vw;
}

.App-link {
  color: #61dafb;
}

.content-left {
  padding: 1em;
  min-width: 50vw;
  color: #ffffff;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.5);
}

.content-right {
  width: 30vw;
  min-width: 340px;
  margin: 16px 0;
  font-size: 20px;
  /*background-color: rgba(255, 255, 255, 0.6);*/
}

.content-right > .links {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}

.content-right > .about {
  padding: 1px 18px;
  background: #efefef;
  border-radius: 2px;
  border-bottom: 4px solid #131313;
}

.content-right p {
  color: #232323;
  font-size: 16px;
  font-family: 'Lexend Bold', sans-serif;
}

.content-left > .my-name {
  font-size: 10vmax;
  line-height: 9vmax;
}

.content-left > .my-title {
  font-size: 24px;
}

.content-right > div {
  padding: 8px;
}

ul, li {
  list-style: none;
  display: block;
  padding: 2px 4px;
  font-size: 0.9em;
}

a {
  color: #efefef;
  text-decoration: none;
}

.tooltip {
  display: inline;
  position: relative;
}

.tooltip:hover:before{
  border: solid;
  border-color: #000000B2 transparent;
  border-width: 6px 6px 0 6px;
  bottom: 20px;
  content: "";
  left: 50%;
  position: absolute;
  z-index: 98;
}

.tooltip:hover:after{
  background: #000000B2;
  background: rgba(0,0,0,.7);
  border-radius: 2px;
  bottom: 26px;
  color: #fff;
  content: attr(title);
  left: 10%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 220px;
  font-size: 14px;
}

@media all and (max-width: 980px) {
  .App-main-container {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }

  .content-right {
    min-width: 90vmin;
  }
}
